<template>
    <div class="content">
      <div class="a">
        <JsonExcel :fields="exportDataStandard" :data="exportData" type="xls" name="车辆缴费记录" :fetch="createExportData" :before-finish="finishDownload" :footer="exportFooter" worksheet="车辆缴费记录">
            <el-button type="primary" class="el-icon-download"> 导出</el-button>
        </JsonExcel>
        <el-button type="primary" class="el-icon-upload2" @click='refreshUpload' style="margin-left: 10px;"> 重新上传</el-button>
      </div>
  
  
      <el-card style="margin-top:15px;width:100%;" >
            <el-row :gutter="10">
              <el-col :xs="24" :sm="12" :md="6" :lg="4">
                <div class="search">
                  <label>车牌号：</label>
                  <el-input v-model="searchForm.plateno" class="marg" style="width:150px"></el-input>
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="6">
                <div class="a">
                  <el-select v-model="searchForm.time_type" style="width:140px;margin-right:5px">
                      <el-option label="出场时间" :value="0"></el-option>
                      <el-option label="入场时间" :value="1"></el-option>
                  </el-select>
                   <el-date-picker v-model="searchForm.time" type="datetimerange" range-separator="-" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="6" :lg="4">
                <div class="search">
                  <label>卡类：</label>
                  <el-select v-model="searchForm.card_id" style="width:150px">
                      <el-option label="全部" :value="0"></el-option>
                      <el-option v-for="item in cardDataList" :key="item.card_id" :label="item.card_name" :value="item.card_id"></el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="6" :lg="4">
                <div class="search">
                  <label>车型：</label>
                  <el-select v-model="searchForm.car_type" style="width:150px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="小车" :value="1"></el-option>
                    <el-option label="大车" :value="2"></el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="6" :lg="4">
                <div v-if="User.user_type!==5" class="search">
                  <label>车场：</label>
                  <el-select v-model="searchForm.pk_id" style="width:150px" @change="parkChanged">
                    <el-option v-if="User.user_type===5" label="全部" :value="0"></el-option>
                    <el-option v-for="item in ParkDataList" :key="item.pk_id" :label="item.pk_name" :value="item.pk_id"></el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="10" style="margin-top: 15px;">
              <el-col :xs="24" :sm="12" :md="6" :lg="4">
                <div class="search">
                  <label>缴费方式：</label>
                  <el-select v-model="searchForm.pay_type" style="width:150px;margin-right:5px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="现金收费" :value="1"></el-option>
                    <el-option label="场内预付" :value="2"></el-option>
                    <el-option label="超时未收费" :value="3"></el-option>
                    <el-option label="出口扫码付费" :value="4"></el-option>
                    <el-option label="免费放行" :value="5"></el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="6" :lg="4">
                <div class="search">
                  <label>操作员：</label>
                  <el-select v-model="searchForm.user_id" style="width:150px;margin-right:5px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option v-for='item in UserDataList' :key="item.user_id" :label="item.user_name" :value="item.user_id"></el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="6" :lg="4">
                <div class="search">
                  <label>岗亭：</label>
                  <el-select v-model="searchForm.post_id" style="width:150px;margin-right:5px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option v-for="item in $store.state.PostDataList" :label="item.post_name" :value="item.post_id" :key="item.post_id"></el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :xs="12" :sm="8" :md="6" :lg="2">
                <div class="search">
                  <el-button type="primary" plain @click="Search">查询</el-button>
                </div>
              </el-col>
            </el-row>

      </el-card>
  
      <div style="margin-top:15px;width:100%;">
        <el-row :gutter="10">
          <el-col :xs="12" :sm="8" :md="6" :lg="3">
            <div class="content b">
              <label class="c">应总收</label>
              <label class="d">{{toDecimal2(shouldChargeTotal)+' 元'}}</label>
            </div>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="3">
            <div class="content b">
              <label class="c">实总收</label>
              <label class="d">{{toDecimal2(chargeTotal)+' 元'}}</label>
            </div>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="3">
            <div class="content b">
              <label class="c">现金收入</label>
              <label class="d">{{toDecimal2(moneyTotal)+' 元'}}</label>
            </div>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="3">
            <div class="content b">
              <label class="c">出口扫码支付</label>
              <label class="d">{{toDecimal2(appTotal)+' 元'}}</label>
            </div>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="3">
            <div class="content b">
              <label class="c">场内预收</label>
              <label class="d">{{toDecimal2(prepayTotal)+' 元'}}</label>
            </div>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="3">
            <div class="content b">
              <label class="c">折扣费用</label>
              <label class="d">{{toDecimal2(disTotal)+' 元'}}</label>
            </div>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="3">
            <div class="content b">
              <label class="c">免费金额</label>
              <label class="d">{{toDecimal2(freeTotal)+' 元'}}</label>
            </div>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="3">
            <div class="content b">
              <label class="c">异常收费</label>
              <label class="d">{{toDecimal2(errTotal)+' 元'}}</label>
            </div>
          </el-col>
        </el-row>
      </div>



      <el-table :data='DataList' border style="width: 100%;font-size:13px"  max-height='calc(100vh - 375px)' height='calc(100vh - 375px)' stripe :highlight-current-row='true' size="mini">
            <el-table-column v-if="User.user_type!==5" align="center" prop="park_name" label="所属车场" min-width="82px"></el-table-column>
            <el-table-column fixed align="center" prop="log_plateno" label="车牌号" min-width="90px"></el-table-column>
            <el-table-column align="center" prop="owner_name" label="姓名"></el-table-column>
            <el-table-column align="center" prop="card_name" label="卡型"></el-table-column>
            <el-table-column align="center" prop="log_car_type" label="车型">
              <template slot-scope="scope">
                {{scope.row.log_car_type===0?'小车':'大车'}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="pk_name" label="车场"></el-table-column>
  
            <el-table-column align="center" label="进出场信息">
              <el-table-column align="center" prop="log_in_time" label="入场时间" min-width="145px">
                <template slot-scope="scope">
                  {{FormData(scope.row.log_in_time)}}
                </template>
              </el-table-column>
              <el-table-column align="center" prop="in_gate_name" label="入场通道"></el-table-column>
              <el-table-column align="center" prop="in_post_name" label="入场岗亭"></el-table-column>
              <el-table-column align="center" prop="in_user_name" label="入场操作员"></el-table-column>
              <el-table-column align="center" prop="log_out_time" label="出场时间" min-width="145px">
                <template slot-scope="scope">
                  {{FormData(scope.row.log_out_time)}}
                </template>
              </el-table-column>
              <el-table-column align="center" prop="out_gate_name" label="出场通道"></el-table-column>
              <el-table-column align="center" prop="out_post_name" label="出场岗亭"></el-table-column>
              <el-table-column align="center" prop="out_user_name" label="出场操作员"></el-table-column>
              <el-table-column align="center" prop="log_time_len" label="停车时长" min-width="100px"></el-table-column>
            </el-table-column>
  
            <el-table-column align="center" label="缴费信息">
              <el-table-column align="center" prop="log_should_charge" label="应收(元)">
                <template slot-scope="scope">
                  {{toDecimal2(scope.row.log_should_charge)}}
                </template>
              </el-table-column>
              <el-table-column align="center" prop="log_charge" label="实收(元)">
                <template slot-scope="scope">
                    {{toDecimal2(scope.row.log_charge)}}
                </template>
              </el-table-column>
              <el-table-column align="center" prop="log_charge" label="优惠(元)">
                <template slot-scope="scope">
                  {{toDecimal2(((scope.row.log_discount_charge!==null&&(scope.row.center_discount_charge===0||scope.row.center_discount_charge===null))?scope.row.log_discount_charge:0)+(scope.row.center_discount_charge===null?0:scope.row.center_discount_charge))}}
                </template>
              </el-table-column>
              <el-table-column align="center" prop="log_charge" label="免费(元)">
                <template slot-scope="scope">
                    {{toDecimal2(scope.row.log_state===5?scope.row.log_should_charge:0)}}
                </template>
              </el-table-column>

              <el-table-column align="center" label="场内预付">
                <el-table-column align="center" prop="center_charge_time" label="预付时间">
                  <template slot-scope="scope">
                    {{FormData(scope.row.center_charge_time)}}
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="center_charge" label="预付金额(元)">
                  <template slot-scope="scope">
                    {{toDecimal2(scope.row.center_charge)}}
                  </template>
                </el-table-column>
              </el-table-column>
  
            </el-table-column>
            <el-table-column align="center" prop="log_card_status" label="卡类改变原因">
               <template slot-scope="scope">
                {{ChangeCardTypeReason(scope.row.log_card_status)}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="log_state" label="支付方式">
               <template slot-scope="scope">
                  {{CheckLogState(scope.row)}}
              </template>
            </el-table-column>
      </el-table>
      <el-pagination style="width:100%" layout="sizes,prev, pager, next" :page-sizes="[15, 30, 45, 60]" @current-change="current_change" :total="searchForm.total" background :page-size="searchForm.pageSize" @size-change="handleSizeChange"></el-pagination>
  
    </div>
  </template>
  
  <script>
  import util from '../../../util/util'
  import JsonExcel from 'vue-json-excel'
  
  export default {
    data: function () {
      return {
        User:null,
        searchForm: {
          plateno: '',
          time_type: 0,
          pk_id:0,
          time: [],
          pay_type:0,
          mc_id:0,
          dis_Id:0,
          card_id: 0,
          car_type: 0,
          pageSize: 15,
          pageNo: 1,
          total: 0,
          user_id:0,
          post_id:0
        },
        ParkDataList:[],
        UserDataList:[],
        CardTypeDataList: [],
        DataList: [],
        shouldChargeTotal:0,
        chargeTotal:0,
        moneyTotal:0,
        appTotal:0,
        prepayTotal:0,
        disTotal:0,
        freeTotal:0,
        errTotal:0,


        inImgDialogVisible: false,
        outImgDialogVisible:false,
  
         // 导出
         exportDataStandard: {
          所属车场: "park_name" ,
          车牌号: "log_plateno" ,
          姓名: "owner_name" ,
          卡型: "card_name" ,
          车型: {
            field: 'log_car_type',
            callback: value => {
              return value===0?'小车':'大车'
            }
          },
          车场: "pk_name" ,
          入场时间: {
            field: 'log_in_time',
            callback: value => {
              return this.FormData(value)
            }
          },
          入场通道: "in_gate_name" ,
          入场岗亭: "in_post_name" ,
          入场操作员: "in_user_name" ,
          出场时间:  {
            field: 'log_out_time',
            callback: value => {
              return this.FormData(value)
            }
          },
          出场通道: "out_gate_name" ,
          出场岗亭: "out_post_name" ,
          出场操作员: "out_user_name" ,
          停车时长: "log_time_len" ,
          应收: {
            field: 'log_should_charge',
            callback: value => {
                 return this.toDecimal2(value)
            }
          },
          实收: {
            field: 'log_charge',
            callback: value => {
                 return this.toDecimal2(value)
            }
          },
          优惠: {
            callback: row => {
                 return this.toDecimal2(((row.log_discount_charge!==null&&(row.center_discount_charge===0||row.center_discount_charge===null))?row.log_discount_charge:0)+(row.center_discount_charge===null?0:row.center_discount_charge))
            }
          },
          免费: {
            callback: row => {
              return this.toDecimal2(row.log_state===5?row.log_should_charge:0)
            }
          },
          场内预付时间:{
            field: 'center_charge_time',
            callback: value => {
                 return this.FormData(value)
            }
          },
          预付金额:{
            field: 'center_charge',
            callback: value => {
                 return this.toDecimal2(value)
            }
          },
          卡类改变原因:{
            field: 'log_card_status',
            callback: value => {
              return this.ChangeCardTypeReason(value)
            }
          },
          支付方式: {
            callback: value => {
              return this.CheckLogState(value)
            }
          }
        },
        exportData: [],
        exportFooter:''
      }
    },
    computed: {
      cardDataList(){
        if(this.User.user_type===5){
            return this.$store.state.CardDataList
          }
        else{
          return this.CardTypeDataList
        }
      },
  
      FormData () {
        return function (time) {
          if(time===null||time===undefined||time==='')
            return ''
          else
            return util.FormateDate(time, 2)
        }
      },
      ChangeCardTypeReason () {
        return function (t) {
          if (t === -1) {
            return '一位多车，按临时车进'
          } else if (t === -2) {
            return '已过期，按临时车进'
          } else { return '' }
        }
      },
      toDecimal2(){
        return function(num){
          if(num===null){num=0}
          return util.toDecimal2(num)
        }
      },
      CheckLogState () {
        return function (row) {
           var msg=''
          if (row.log_state === 0 && (row.log_charge === 0 || row.log_charge === null) && (row.center_charge === 0 || row.center_charge === null)) {
            console.log('1')
            msg= '自动放行出场'
          }
          if (row.log_state === 0 && row.log_charge > 0) {
            msg= '现金支付'
          }
          if (row.log_state === 0 && (row.log_charge === null || row.log_charge === 0) && row.center_charge > 0) {
            msg= '场内预付'
          }
          if (row.log_state === 1) {
            msg= '超时未收费'
          }
          if (row.log_state === 2) {
            msg= '脱机记录'
          }
          if (row.log_state === 3) {
            msg= 'app支付'
          }
          if (row.log_state === 4 && row.log_charge > 0) {
            msg= '未支付'
          }
          if (row.log_state === 0 && row.log_charge==0&&(row.log_discount_charge>0||row.center_discount_charge>0)) {
            msg= '优惠减免放行'
          }
          if (row.log_state === 5) {
            msg= '免费放行'
          }
          if (row.log_state === 7) {
            msg= '无感支付'
          }
          if (row.log_state === 8) {
            msg= 'etc付费'
          }
          return msg
        }
      }
    },
    components: {
      JsonExcel
    },
    created () {
      this.User = util.JesToken()
      this.getParkDataList()

      if(this.$store.state.PostDataList.length===0){
        this.$store.dispatch('getPostDataList')
      }
    },
    methods: {
      getParkDataList(){
          if(this.User.user_type===5){
            if(this.$store.state.CardDataList.length===0){
                this.$store.dispatch('getCardDataList')
              }
              this.GetDataList()
              this.GetUserDataList()
          }
          else{
            util.Get('parkmanage/getparkdatalist').then(res => {
              if (res.rpStatus === 10000) {
                 this.ParkDataList = res.list
                 if(res.list.length>0){
                   this.searchForm.pk_id=res.list[0].pk_id
                   this.getCardDataList()
                   this.GetDataList()
                   this.GetUserDataList()
                 }
              }
            })
          }
      },
      getCardDataList(){
        util.Get('card/getcarddatalist?id='+this.searchForm.pk_id).then(res => {
            if (res.rpStatus === 10000) {
              this.CardTypeDataList = res.list.filter(a => a.card_limit!==8)
            }
        })
      },
      parkChanged(value){
        this.searchForm.pk_id=value
        this.getCardDataList()
        this.searchForm.card_id=0
        this.GetUserDataList()
        this.GetDataList()
      },
      GetUserDataList(){
          util.Get('user/getuserdataList?id='+this.searchForm.pk_id+'&userType=5').then(res=>{
              if(res.rpStatus===10000)
                  this.UserDataList=res.list
          })
      },
      GetDataList () {
        this.searchForm.begintime=''
        this.searchForm.endtime=''
        if(this.searchForm.time&&this.searchForm.time.length>0){
          this.searchForm.begintime=this.searchForm.time[0]
          this.searchForm.endtime=this.searchForm.time[1]
        }
        util.Get('logtempcharge/getlogchargedataList', this.searchForm).then(res => {
          if (res.rpStatus === 10000) {
            if (res.list.length > 0) {
              res.list.forEach(a => {
                a.picdata = []
              })
            }
            this.DataList = res.list
            this.searchForm.total = res.total
          }
        })
        util.Get('logtempcharge/getsumlogcharge', this.searchForm).then(res => {
          if (res.rpStatus === 10000) {
            this.shouldChargeTotal=res.list[0]
            this.chargeTotal=res.list[1]
            this.moneyTotal=res.list[2]
            this.appTotal=res.list[3]
            this.prepayTotal=res.list[4]
            this.disTotal=res.list[5]
            this.freeTotal=res.list[6]
            this.errTotal=res.list[7]
          }
        })
      },
      Search () {
        if(util.CheckUserAuth('4-8-1')){
          this.GetDataList()
        }
      },
      current_change (page) {
        this.searchForm.pageNo = page
        this.GetDataList()
      },
      handleSizeChange(pageSize){
        this.searchForm.pageSize=pageSize
        this.GetDataList()
      },

      refreshUpload(){
        util.Send('refresh_upload_tempcharge',this.searchForm.pk_id)
      },
      // 导出
      async createExportData () {
        if(util.CheckUserAuth('4-8-2')){
          this.searchForm.pageNo = 0
          var form = JSON.parse(JSON.stringify(this.searchForm))
          form.pageNo = 0
    
          var result = await util.Get('logtempcharge/getlogchargedataList', form)
          if (result.rpStatus === 10000) {
            this.exportData = result.list
            this.exportFooter="应总收："+this.shouldChargeTotal+"元\xa0\xa0\xa0\xa0\xa0实总收："+this.chargeTotal+"元\xa0\xa0\xa0\xa0\xa0现金收入："+this.moneyTotal
            +"元\xa0\xa0\xa0\xa0\xa0出口扫码支付："+this.appTotal+"元\xa0\xa0\xa0\xa0\xa0场内预付："+this.prepayTotal+"元\xa0\xa0\xa0\xa0\xa0折扣费用："+this.disTotal
            +"元\xa0\xa0\xa0\xa0\xa0免费："+this.freeTotal+"元\xa0\xa0\xa0\xa0\xa0异常收费："+this.errTotal+"元"
            return this.exportData
          }
        }
      },
      finishDownload () {
        this.$message({
          message: '导出数据成功',
          type: 'success'
        })
        this.exportData=[]
      }
    }
  }
  </script>
  
  <style scoped>
    .content{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
  }
  .a{
    display:flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
  .search{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;
  }
  .search label{
    margin-right: 8px;
    width: 80px;
    text-align: right;
  }
  .el-table{
      margin-top: 10px;
      /* max-height: calc(100vh - 300px);
      height: calc(100vh - 250px); */
  }
  
  .b{
    background-color:#F9F7FA;
    align-items: center;
    height: 70px;
    justify-content: center;
  }
  .c{
    color: #999999;
  }
  .d{
    margin-top: 10px;
    font-weight: 600;
  }


  ::v-deep.el-table thead {
    color: #666666;
    font-weight: 500;
  }
  
  </style>
  